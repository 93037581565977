import React from 'react';
import Header from './components/header/header';
import Nav from './components/gallery/gallery';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from "react-router-dom";

const App = () => (
    <Router>
        <Switch>
        <Route path='*' render={props =>
            <>
                <Header />
                <Nav browser={props} />
            </>
        } />
            <Redirect to="/" />
        </Switch>
    </Router>
);

export default App;
