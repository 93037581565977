import React, { Component } from 'react';
import './header.scss'
import AOS from 'aos'
import 'aos/dist/aos.css';

class Header extends Component {

    state = {
        visible: false
    }

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.setState(() => ({
                visible: false
            }));
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    openModal(arg) {
        this.setState({
            visible: arg
        });
    }

    render() {

        let style = {
            width: '233px'
        };

        AOS.init();
        return (
            <div>
                <div className="container">
                    <div data-aos="fade-down" data-aos-duration="2500">
                        <div className="header">
                            <h1>
                                Ekaterina Katerinina 
                            </h1>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-up"
                    data-aos-anchor-placement="bottom-bottom">
                    <div className="container contacts" style={style}>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Ekaterina.Katerinina/">Facebook</a>
                        <a href="tel:+37360771155">+37360771155</a>
                    </div>
                </div>
            </div>
        )
    }
}


export default Header;
