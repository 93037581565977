import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css'

import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloProvider } from 'react-apollo'

const GRAPHCMS_API = 'https://api-us-west-2.graphcms.com/v2/ck1nf5vvh2mhe01df6zv0bjjv/master'

const client = new ApolloClient({
    link: new HttpLink({ uri: GRAPHCMS_API }),
    cache: new InMemoryCache()
})


ReactDOM.render(
    <ApolloProvider client={client}>
        <App />
    </ApolloProvider>,
    document.getElementById('root'));
