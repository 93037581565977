import React, { Component } from 'react';

import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import AOS from 'aos'
import 'aos/dist/aos.css';
import Gallery from "react-photo-gallery";
import Lightbox from 'react-images';

import { Link } from 'react-router-dom'

import './galary.scss';
import logo from './tagme-logo.png'

const _ = require('lodash')


class Nav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentImage: 0,
            loading: true,
            photos: [],
            initPhotos: [],
            groups: [],
            allSortedPhotos: [],
            firstLoad: false,
            activeLink: null
        };
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
        this.active = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        const { data: { loading, galleries } } = this.props;
        const url = (this.props.browser.location.pathname).replace(/^\/|\/$/g, '').toUpperCase()

        if (!loading) {
            if (prevProps !== this.props) {
                this.setState({ loading })

                galleries.sort((a, b) => {
                    return a.index - b.index
                })

                let groups = ['ВСЕ'];
                let photos = [];
                if (!_.isEmpty(galleries)) {
                    //* Add groups to the state
                    for (const key in galleries) {
                        const obj = galleries[key];
                        if (obj.stage === "PUBLISHED") {
                            groups.push(obj.group.trim().toUpperCase())
                        }
                    }
                    //* Add photos to the state
                    for (const key in galleries) {
                        const { stage, index, show } = galleries[key];
                        const obj = galleries[key].photo;
                        const group = galleries[key].group;
                        if (stage === "PUBLISHED") {
                            photos.push({
                                src: obj.url,
                                width: obj.width,
                                height: obj.height,
                                group: group.trim().toUpperCase(),
                                index,
                                show: show.toString()
                            })
                        }
                    }
                    //* Sort and delete same groups
                    const sortedGroups = groups.reduce(function (a, b) {
                        if (a.indexOf(b) < 0) a.push(b);
                        return a;
                    }, []);

                    const allSortedPhotos = _.filter(photos, o => o.show === 'true')
                    this.setState({
                        groups: sortedGroups,
                        photos,
                        initPhotos: photos,
                        allSortedPhotos,
                        firstLoad: true,
                        activeLink: url
                    })
                    this.sortPhotos(url)
                }
            }
        }

        if (prevState.firstLoad !== this.state.firstLoad) {
            if (_.isEmpty(url)) {
                this.sortPhotos('ВСЕ')
            } else {
                this.sortPhotos(url)
            }
        }
    }
    
    sortPhotos = arg => {
        const { initPhotos, allSortedPhotos } = this.state;
        
        let newObj = []
        if (arg === 'ВСЕ') {
            this.setState({ photos: allSortedPhotos })
        } else {
            for (const key in initPhotos) {
                const item = initPhotos[key]
                if (item.group === arg) newObj.push(item);
            }
            this.setState({ photos: newObj })
        }
    }

    renderGroups = () => {
        const { groups, activeLink } = this.state;
        const activeStyle = {
            opacity: 1,
            transform: 'scale(1.1)',
            outline: 'none'
        }

        return groups.map(item => {
            const link = (`/${item}`).toLowerCase()
            const active = activeLink === item ? true : false
            if (active) {
                return <Link to={link} key={item} style={{ textDecoration: 'none' }}>
                    <button key={item} style={activeStyle} onClick={() => this.sortPhotos(link)}>{item}</button>
                </Link>
            } else {
                return <Link to={link} key={item} style={{ textDecoration: 'none' }}>
                    <button key={item} onClick={() => this.sortPhotos(link)}>{item}</button>
                </Link>
            }
        }
        )
    }

    openLightbox(event, obj) {
        this.setState({
            currentImage: obj.index,
            lightboxIsOpen: true,
        });
    }

    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }

    gotoPrevious() {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }

    gotoNext() {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }

    render() {
        const { photos } = this.state;
        AOS.init();
        return (
            <div>
                <div className="container">
                    <div data-aos="flip-up">
                        <div className="row">
                            <div className="mobile-button">
                                {this.renderGroups()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gallery">
                    <div className="container">
                        <Gallery photos={photos} onClick={this.openLightbox} margin={5} />

                        <Lightbox images={photos}
                            onClose={this.closeLightbox}
                            onClickPrev={this.gotoPrevious}
                            onClickNext={this.gotoNext}
                            currentImage={this.state.currentImage}
                            isOpen={this.state.lightboxIsOpen}
                        />
                    </div>
                </div>
                <a target='_blank' rel="noopener noreferrer" href="https://www.facebook.com/studiotagme/">
                    <img src={logo} alt="logo" width='230' className='logo' />
                </a>
            </div>
        );
    }
}

const query = gql`
{
  galleries {
    id
    index
    stage
    photo {
      width
      height
      url
    }
    group
    show
  }
}
`

export default graphql(query)(Nav);
